import React from "react";
import "./style.scss";

const Hero = () => {
    return (
      <div className="row">
        <section className="meetup-hero__wrapper">
          <h1 className="meetup-hero meetup-hero__heading jkl-body">
            Meetup Beyond
            <br />
            Design Systems
          </h1>
          <a
            href="https://www.youtube.com/watch?v=b44oKTzc6vU"
            target="_blank"
            rel="noreferrer"
            className="meetup-button-link jkl-button jkl-button--compact jkl-button--secondary jkl-button--inverted"
          >
            Watch the event
          </a>
        </section>
      </div>
    );
};

export default Hero;
